var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = "/error-monitoring/sentry";
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"34"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

/* @flow */

import { CaptureConsole, ExtraErrorData } from "@sentry/integrations";

import initSentry from "./sentry.shared.config"

initSentry({
    integrations: [
        new CaptureConsole({
            levels: ["warn", "error"],
        }),
        new ExtraErrorData(),
    ],
});
